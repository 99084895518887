export default [
  {
    header: '',
  },
  {
    title: 'Tablas Basicas',
    route: 'home',
    icon: 'HomeIcon',
    children: [
      // {
      //   title: 'Home',
      //   route: 'home',
      //   icon: 'HomeIcon',
      // },
      // {
      //   title: 'Second Page',
      //   route: 'second-page',
      //   icon: 'FileIcon',
      // },
      {
        route: '/users',
        title: 'Usuarios',
      },
      {
        route: '/roles',
        title: 'Roles',
      },
      {
        route: '/employees',
        title: 'Empleados',
        tag: '1',
      },
      {
        route: 'entities',
        title: 'Entidades',
      },
      {
        route: '/labs',
        title: 'Laboratorios',
      },
      {
        route: '/exams',
        title: 'Examenes',
      },
      {
        route: '/services',
        title: 'Servicios',
      },
      {
        route: '/contracts',
        title: 'Contratos',
      },
      {
        route: '/forms',
        title: 'Formularios',
      },
    ],
  },
]
// export default [
//   {
//     url: '/',
//     name: 'Home',
//     slug: 'home',
//     icon: 'HomeIcon'
//   },
//   {
//     url: null,
//     name: 'Tablas Basicas',
//     icon: 'SettingsIcon',
//     submenu: [
//       {
//         url: '/users',
//         name: 'Usuarios',
//         // slug: 'dashboard-analytics',
//         // i18n: 'Analytics'
//       },
//       {
//         url: '/roles',
//         name: 'Roles',
//       },
//       {
//         url: '/employees',
//         name: 'Empleados',
//         tag: '1',
//         tagColor: 'warning',
//       },
//       {
//         url: '/entities',
//         name: 'Entidades',
//       },
//       {
//         url: '/labs',
//         name: 'Laboratorios',
//       },
//       {
//         url: '/exams',
//         name: 'Examenes',
//       },
//       {
//         url: '/services',
//         name: 'Servicios',
//       },
//       {
//         url: '/contracts',
//         name: 'Contratos',
//       },
//       {
//         url: '/forms',
//         name: 'Formularios',
//       }
//     ]
//   },
//   {
//     url: null,
//     name: 'Clinica',
//     icon: 'ThermometerIcon',
//     submenu: [
//       {
//         url: '/users',
//         name: 'Ingreso Pacientes',
//       },
//       {
//         url: '/employees',
//         name: 'Historia clinica',
//         tag: '1',
//         tagColor: 'warning',
//       }
//     ]
//   },
//   {
//     url: null,
//     name: 'Facturacion',
//     icon: 'CreditCardIcon',
//     submenu: [
//       {
//         url: '/users',
//         name: 'Ordenes',
//       },
//       {
//         url: '/employees',
//         name: 'Centros de Costo'
//       },
//       {
//         url: '/employees',
//         name: 'Cuentas cliente'
//       }
//     ]
//   },
//   {
//     url: '/',
//     name: 'Home',
//     slug: 'home',
//     icon: 'HomeIcon'
//   },
//   {
//     url: '/movements',
//     name: 'Movimientos',
//     slug: 'movements',
//     icon: 'ActivityIcon'
//   },
//   {
//     url: '/table',
//     name: 'table ',
//     slug: 'page3',
//     icon: 'FileIcon'
//   },
// ]
